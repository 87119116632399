<template>
  <v-dialog v-model="data.dialog" persistent max-width="600px">
    <v-card v-if="item">
      <v-toolbar
        dark
        color="primary"
      >
        <v-btn
          icon
          dark
          @click="close()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ item.title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            :loading="submitLoading"
            style="height: 100% !important;"
            dark
            text
            class="full-btn"
            @click="exportExcel()"
          >
            تصدير اكسل
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <div class="text-center">
        <v-data-table
          :items="item.entries"
          :headers="headers"
          :items-per-page="-1"
          hide-default-footer
          :loading="loading"
        >
          <template #item.exam_degree>
            {{ item.question_degree * item.question_count }}
          </template>
          <template #item.degree="{ item }">
            {{ item.submit_at ? item.degree : 'لم يسلم اجابته' }}
          </template>
        </v-data-table>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from '@axios'
import * as xlsx from 'xlsx'
import * as FileSaver from 'file-saver'

export default {
  name: 'EntriesDialog',
  props: {
    data: {
      type: Object,
      default: () => ({
        dialog: false,
        id: null,
        type: 'show',
      }),
    },
    dialogModal: {
      type: Function,
      default: state => {
        this.data.dialog = state
      },
    },
    translationKey: {
      type: String,
      default: () => '',
    },
  },
  emits: ['update:table'],
  data: () => ({
    loading: true,
    submitLoading: false,
    valid: false,
    item: null,
    headers: [
      {
        text: 'اسم الطالب',
        value: 'student.name',
      },
      {
        text: 'درجة الطالب',
        value: 'degree',
      },
      {
        text: 'درجة الامتحان',
        value: 'exam_degree',
      },
    ],
  }),
  computed: {
    value() {
      return `qr:${this.item.id}`
    },
  },
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(val) {
        try {
          this.loading = true
          if (val.id) {
            await this.fetch(val.id)
          }
        } catch (error) {
          //   await toast.dispatch('error', error.response ? error.response.data.message : error.message)
        } finally {
          this.loading = false
        }
      },
    },
  },
  methods: {
    $(key, parameters = null) {
      return this.$t(`${this.translationKey}.modal.${key}`, parameters)
    },
    close() {
      this.$emit('update:table')
      this.data.dialog = false
    },
    async fetch(id) {
      const day = await axios.get(`/exams/${id}/entries`)
      this.item = day.data.data
    },
    async exportExcel() {
      const examDegree = this.item.question_degree * this.item.question_count
      const data = this.item.entries.map((entry, index) => {
        const entryData = {
          index,
          name: entry.student ? entry.student.name : 'طالب محذوف',
          degree: entry.degree,
          examDegree,
          date: entry.submit_at ? new Date(entry.submit_at).toLocaleDateString('ar-EG-u-nu-latn', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          }) : 'لم يسلم',
        }
        entry.questions.forEach((question, i) => {
          entryData[`question_${i}`] = question.question.question
          entryData[`correct_answer_${i}`] = question.question.correct_answer.join(', ')
          entryData[`answer_${i}`] = question.answers.join(', ')
        })

        return entryData
      })
      const headers = [[
        '#',
        'الاسم',
        'درجة الطالب',
        'درجة الامتحان',
        'وقت التسليم',
      ]]
      for (let i = 1; i <= this.item.question_count; i++) {
        headers[0].push(`السؤال ${i}`)
        headers[0].push(`الاجوبة الصحيحة ${i}`)
        headers[0].push(`جواب الطالب ${i}`)
      }
      const wb = xlsx.utils.book_new()
      const ws = xlsx.utils.json_to_sheet([])
      xlsx.utils.sheet_add_aoa(ws, headers)
      xlsx.utils.sheet_add_json(ws, data, {
        origin: 'A2',
        skipHeader: true,
      })

      xlsx.utils.book_append_sheet(wb, ws, 'قائمة الطلبة')
      if (!wb.Workbook) {
        wb.Workbook = {}
      }
      if (!wb.Workbook.Views) {
        wb.Workbook.Views = []
      }
      if (!wb.Workbook.Views[0]) {
        wb.Workbook.Views[0] = {}
      }
      wb.Workbook.Views[0].RTL = true
      const excelBuffer = xlsx.write(wb, {
        bookType: 'xlsx',
        type: 'array',
      })
      this.saveAsExcelFile(excelBuffer, 'نتائج الامتحان')
    },
    saveAsExcelFile(buffer, fileName) {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
      const EXCEL_EXTENSION = '.xlsx'
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      })
      console.log(this.item)
      FileSaver.saveAs(data, `${fileName}_${this.item.title}_${this.item.stage ? this.item.stage.name : 'x'}_${this.item.subject ? this.item.subject.name : 'x'}.${EXCEL_EXTENSION}`)
    },
  },
}
</script>

<style scoped>
.full-btn {
  height: 100% !important;
}
</style>
